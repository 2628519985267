import {Fragment, useState} from 'react'
import MENUITEMS from '../Storage/Pizzas'



const Pizza = ()=>{

    const [view, setview]= useState({
        isSwitch: false,
        data: undefined
    });


    const OrderFragment = ()=>{

        const [cart, setCart] = useState([]);

        const addHandler = e =>{
            var array = cart;
            var newarray = [...array, e];
            setCart(newarray);    
        }
        return (
            <div>
            {view.isSwitch !== false && <div>
                <div className="fixed inset-0 w-full h-full z-60 bg-black bg-opacity-50 duration-300 overflow-y-auto" style={{background: 'rgba(0,0,0,.4)'}} id="modal-id">
                <div  className="w-5/12 p-5 mt-10 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
                {/*content*/}
                <div  >
                    {/*body*/}
                    <header className="flex items-center justify-between p-2">
                    <button onClick={()=>{setview({isSwitch: false, data: view.data})}} className="focus:outline-none p-2" >
                    <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                    </svg>
                    </button>
            </header>
                    <div className=" text-center p-5 flex-auto justify-center">
                    <h1 className=" px-5 font-bold text-left text-4xl text-gray-800">
                    {view.data.name}
                    </h1>
                    <br/>
                    <div className=" py-6">
                        <div className="grid  lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 grid-cols-3 gap-4 px-4">
                    <button onClick={()=>{addHandler({name: "10' " +view.data.name + " pizza", price : view.data.Small})}} className="bg-red-500 border-red-500 dark:bg-gray-800 bg-opacity-95 border-opacity-60 | p-4 border-solid rounded-3xl border-2 | flex justify-around cursor-pointer | hover:bg-indigo-400 dark:hover:bg-indigo-600 hover:border-transparent | transition-colors duration-500">
                                <div className="flex flex-col justify-center">
                                <p className="text-gray-100 font-bold text-2xl dark:text-gray-100 font-semibold">Small</p>
                                <p className="text-gray-100 font-bold dark:text-gray-100 font-semibold"> £ {view.data.Small}</p>
                    </div>
                    </button>
                    <button onClick={()=>{addHandler({name: "12' "+view.data.name + " pizza ", price : view.data.Medium})}}  className="bg-red-500 border-red-500 dark:bg-gray-800 bg-opacity-95 border-opacity-60 | p-4 border-solid rounded-3xl border-2 | flex justify-around cursor-pointer | hover:bg-indigo-400 dark:hover:bg-indigo-600 hover:border-transparent | transition-colors duration-500">
                                <div className="flex flex-col justify-center">
                                <p className="text-gray-100 font-bold text-2xl dark:text-gray-100 font-semibold">Medium</p>
                                <p className="text-gray-100 font-bold dark:text-gray-100 font-semibold"> £ {view.data.Medium}</p>
                    </div>
                    </button>
                    <button onClick={()=>{addHandler({name: "16' "+view.data.name + " pizza ", price : view.data.Large})}}  className="bg-red-500 border-red-500 dark:bg-gray-800 bg-opacity-95 border-opacity-60 | p-4 border-solid rounded-3xl border-2 | flex justify-around cursor-pointer | hover:bg-indigo-400 dark:hover:bg-indigo-600 hover:border-transparent | transition-colors duration-500">
                                <div className="flex flex-col justify-center">
                                <p className="text-gray-100 font-bold text-2xl dark:text-gray-100 font-semibold">Large</p>
                                <p className="text-gray-100 font-bold dark:text-gray-100 font-semibold"> £ {view.data.Large}</p>
                    </div>
                    </button>
                    
                    </div>
                    </div>           
                    
                    </div>

                    {cart.length !== 0 && <div>
                    {cart.map((item, index)=>{
                        return(
                            <div key={item.name}>
                            <div onClick={()=>{   
                                var sorted = cart;
                                var targetId ;
                                sorted.forEach((i)=>{
                                    if(i.name == item.name){                                       
                                       targetId = sorted.indexOf(i);                                       
                                        
                                    }
                                })  
                                sorted = sorted.splice(targetId, 1);
                                setCart(sorted)
                                                        }} className="bg-pink-700 rounded shadow p-6 m-4  lg:w-6/7 lg:max-w-lg">
                            <p className="text-gray-100 font-bold text-1xl dark:text-gray-100 font-semibold">{item.name} - {item.price} £ </p>
                            </div>
                            </div>

                        )
                    })}
                    
                    </div>}
               
                    

                    {/*footer*/}
                    <div className="p-3  mt-2 text-center space-x-4 md:block">
                    <button className="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-red-600">Add</button>
                    </div>
                </div>
                </div>
            </div>
                </div>}

            </div>
        )
    }
    
    return (
        <Fragment>
         
         <OrderFragment/>


        {MENUITEMS !== undefined && <div className="">
            {MENUITEMS.pizzas.map((selection, index)=>{
                return <div key={index}>
                <h1 className=" px-5 font-bold text-left text-4xl text-gray-800">
                {selection.category}
                </h1>
                <div className=" py-6">
                 <div className="grid  lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-3 gap-4 px-4">
                {selection.data.map((aPizza, i)=>{
                    return <div onClick={()=>{setview({isSwitch: true, data: aPizza})}}>
                    <div className="bg-gray-100 border-indigo-600 dark:bg-gray-800 bg-opacity-95 border-opacity-60 | p-4 border-solid rounded-3xl border-2 | flex justify-around cursor-pointer | hover:bg-indigo-400 dark:hover:bg-indigo-600 hover:border-transparent | transition-colors duration-500">
                        <img className="w-16 h-16 object-cover" src="/images/pizza.png" alt="" />
                        <div className="flex flex-col justify-center">
                        <p className="text-gray-900 font-bold text-1xl dark:text-gray-300 font-semibold">{aPizza.name}</p>
                        </div>
                    </div>
                    </div>
                })}
                </div>
                 </div>
                 <br/>
                </div>
            })}
        </div>}

     
         
         
         
        
          {/* SMALL CARD ROUNDED */}
         
         
     
            
        </Fragment>
    )
}

export default Pizza;