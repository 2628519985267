
import { LockClosedIcon } from '@heroicons/react/solid'

export default function Login() {
  return (
    <div>
    <div class="absolute w-full top-0 ">
    <div class="absolute top-0 w-full h-full " ></div>
    <div class="container mx-auto px-4 h-full">
        <div class="flex content-center items-center justify-center h-full">
            <div class="w-full lg:w-6/12 px-4 pt-32">
                <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-green-300 border-0">
                    <div class="rounded-t mb-0 px-6 py-6">
                        <div class="text-center mb-3">
                        <h2 class="text-3xl md:text-2xl text-gray-700 font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate">Chillies Admin</h2></div>
                          <hr class="mt-6 border-b-1 border-gray-400"/>
                             </div>
                                <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                                  <form>
                                   <div class="relative w-full mb-3">
                                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="grid-password">Email</label>
                                <input type="email" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full" placeholder="Email" 
                                />
                            </div>
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="grid-password">Password</label>
                                <input type="password" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full" placeholder="Password"  style={{transition: 'all 0.15s ease 0s'}}/>
                            </div>
                            <div>
                                <label class="inline-flex items-center cursor-pointer">
                                    <input id="customCheckLogin" type="checkbox" class="form-checkbox text-gray-800 ml-1 w-5 h-5" style={{transition: 'all 0.15s ease 0s'}}/><span class="ml-2 text-sm font-semibold text-gray-700">Remember me</span></label>
                            </div>
                            <div class="text-center mt-6">
                                <button type="submit" class="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-gray-900 hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in">
                                <span class="mr-2 uppercase">Login</span>
                                <span>
                                  <svg class="h-6 w-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                                    <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                  </svg>
                                </span>
                              </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="flex flex-wrap mt-6">
                    <div class="w-1/2"><a href="#pablo" class="text-gray-300"><small>Forgot password?</small></a></div>
                    <div class="w-1/2 text-right"><a href="#pablo" class="text-gray-300"><small>Create new account</small></a></div>
                </div>
            </div>
        </div>
    </div>

</div>
</div>
  )
}
