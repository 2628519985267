import Routing from './Routes/Routing'
import {Fragment} from 'react'

const App = () => {
  return (
  <Fragment>
    <Routing/>
  </Fragment>
  );
}

export default App;
