import {useState} from 'react'
import Pizza from './components/pizza'

const OrderingLayout = ()=>{

  const [view, setview] = useState({
    pizza : false
  })


    return(
        <section>

        <section>
          {view.pizza !== false && <div>
        <div  className="fixed inset-0 w-full h-full z-20 bg-black bg-opacity-50 duration-300 overflow-y-auto" style={{background: 'rgba(0,0,0,.2)'}} id="modal-id">
        <div className="w-11/12 p-5 mt-8 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
        <header className="flex items-center justify-between p-2">
            <button onClick={()=>{setview({pizza: false})}} className="focus:outline-none p-2" >
            <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
            </svg>
            </button>
      </header>
          {/*content*/}
          <div  >
            {/*body*/}
            <div className=" text-center p-5 flex-auto justify-center">
              <Pizza/>
            </div>
            {/*footer*/}
           </div>
        </div>
      </div>
          </div>}
        </section>






        <div className="content">
        <div className="flex items-center justify-between w-full my-4 pl-4 sm:pr-4 ">
          <div className="mr-6">
            <h2 className="text-3xl md:text-4xl font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate"></h2>
            <div className="font-base tracking-tight text-gray-600">
                        
            <button className="bg-red-600 font-semibold text-white p-2 w-32 rounded-full hover:bg-red-700 focus:outline-none focus:ring shadow-lg hover:shadow-none transition-all duration-300 m-2" >
                Orders
            </button>
            </div>
          </div>
          <div className="flex items-center ">
            <a href="/login" className="bg-gray-900 px-5 py-2 text-sm shadow-sm font-semibold tracking-wider text-white rounded-full hover:bg-gray-800">Admin Account</a>
          </div>
        </div>
        </div>

    
        <div>
        <div className="flex flex-col bg-white m-auto p-auto">
          <h1 className="flex py-5 lg:px-20 md:px-10 px-5 lg:mx-40 md:mx-20 mx-5 font-bold text-6xl text-gray-800">
           Menu
         </h1>
          <br/>
          <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
            <div className="flex flex-nowrap lg:ml-40 md:ml-20 ml-10 ">

              <div onClick={()=>{setview({pizza: true})}} className="inline-block px-3">
                <div className="w-64 bg-green-500  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
                Delicious Pizzas
                </h1>
                  <img src="/images/pizza.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>
              <div className="inline-block px-3">
                <div className="w-64 bg-blue-500  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
                Calzones
                </h1>
                  <img src="/images/calzones.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>

              <div className="inline-block px-3">
                <div className="w-64 bg-pink-500  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
                Starters
                </h1>
                  <img src="/images/starters.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>

              <div className="inline-block px-3">
                <div className="w-64 bg-yellow-500  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
               Tandoori Starters
                </h1>
                  <img src="/images/oven.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>

              <div className="inline-block px-3">
                <div className="w-64 bg-purple-500  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
                 Rice
                </h1>
                  <img src="/images/rice.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>
              <div className="inline-block px-3">
                <div className="w-64 bg-red-500  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
                Accomp animents
                </h1>
                  <img src="/images/pickle.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>

              <div className="inline-block px-3">
                <div className="w-64 bg-green-500  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
                Sauces
                </h1>
                  <img src="/images/sauces.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>

              <div className="inline-block px-3">
                <div className="w-64 bg-pink-800  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
                Breads
                </h1>
                  <img src="/images/bread.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>

              <div className="inline-block px-3">
                <div className="w-64 bg-gray-700  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
                Old Favoruite
                </h1>
                  <img src="/images/old.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>


              <div className="inline-block px-3">
                <div className="w-64 bg-green-400  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
                Biryanies
                </h1>
                  <img src="/images/biryani.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>


              <div className="inline-block px-3">
                <div className="w-64 bg-yellow-700  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
                Kebabs
                </h1>
                  <img src="/images/kebab.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>

              <div className="inline-block px-3">
                <div className="w-64 bg-pink-800  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
                Kebab Extras
                </h1>
                  <img src="/images/sauces.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>

              <div className="inline-block px-3">
                <div className="w-64 bg-indigo-800  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
                Chef Special
                </h1>
                  <img src="/images/chef.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>

              <div className="inline-block px-3">
                <div className="w-64 bg-indigo-200  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
                European Dishes
                </h1>
                  <img src="/images/europe.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>

              <div className="inline-block px-3">
                <div className="w-64 bg-pink-800  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
                chips & Hoggies
                </h1>
                  <img src="/images/chips.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>

              <div className="inline-block px-3">
                <div className="w-64 bg-gray-800  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
                Wrap & Munchy Box
                </h1>
                  <img src="/images/chips.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>

             


              <div className="inline-block px-3">
                <div className="w-64 bg-green-900  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
                Main Meals
                </h1>
                  <img src="/images/main.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>

              <div className="inline-block px-3">
                <div className="w-64 bg-blue-500  dark:bg-gray-800 bg-opacity-95 border-opacity-60  p-4 border-solid rounded-3xl border-2 cursor-pointer h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" >
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                <h1 className=" font-bold text-4xl text-gray-50">
                Set & Pizza Meals
                </h1>
                  <img src="/images/calzones.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                 </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
        <style dangerouslySetInnerHTML={{__html: "\n.hide-scroll-bar {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n.hide-scroll-bar::-webkit-scrollbar {\n  display: none;\n}\n" }} />
      </div>


        

        </section>
    )
}

export default OrderingLayout;