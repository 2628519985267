export default {
    "pizzas": [
        {
            "category": "Plain",
            "data": [
                {                   
                        "id": "203",
                        "name": "Cheese",
                        "Small": "5.50",
                        "Medium": "6.50",
                        "Large": "9.90"
                    
                },
                {                   
                        "id": "204",
                        "name": "Garlic",
                        "Small": "6.20",
                        "Medium": "7.40",
                        "Large": "10.90"
                    
                },
                    {                   
                        "id": "205",
                        "name": "Tomato",
                        "Small": "6.20",
                        "Medium": "7.40",
                        "Large": "10.90"
                    
                     },
                    {                   
                        "id": "206",
                        "name": "Onion",
                        "Small": "6.20",
                        "Medium": "7.40",
                        "Large": "10.90"
                    
                    },
                    {                   
                        "id": "207",
                        "name": "Mushroom",
                        "Small": "6.20",
                        "Medium": "7.40",
                        "Large": "10.90"
                    
                    },

                    {                   
                        "id": "208",
                        "name": "Chicken",
                        "Small": "6.50",
                        "Medium": "8.00",
                        "Large": "12.40"
                    
                    },
                    {                   
                        "id": "209",
                        "name": "Ham",
                        "Small": "6.50",
                        "Medium": "8.00",
                        "Large": "12.40"
                    
                    },
                    {                   
                        "id": "210",
                        "name": "Tuna",
                        "Small": "6.50",
                        "Medium": "8.00",
                        "Large": "12.40"
                    
                    },
                    {                   
                        "id": "211",
                        "name": "Prawn ",
                        "Small": "6.50",
                        "Medium": "8.00",
                        "Large": "12.40"
                    
                    },
                    {                   
                        "id": "212",
                        "name": "Peproni",
                        "Small": "6.50",
                        "Medium": "8.00",
                        "Large": "12.40"
                    
                    },



            ]
        }, 
        {
            "category": "Spicy",
            "data": [
                {                   
                    "id": "213",
                    "name": "Spicy Chicken",
                    "Small": "6.90",
                    "Medium": "8.50",
                    "Large": "12.90"
                
                },
                {                   
                    "id": "214",
                    "name": "Sweet & Sour",
                    "Small": "6.90",
                    "Medium": "8.50",
                    "Large": "12.90"
                
                },
                {                   
                    "id": "215",
                    "name": "Spicy Lamb",
                    "Small": "6.90",
                    "Medium": "8.50",
                    "Large": "12.90"
                
                },
                {                   
                    "id": "216",
                    "name": "Spicy Mine",
                    "Small": "6.90",
                    "Medium": "8.50",
                    "Large": "12.90"
                
                },
                {                   
                    "id": "217",
                    "name": "Chicken Tikka",
                    "Small": "6.90",
                    "Medium": "8.50",
                    "Large": "12.90"
                
                },
                {                   
                    "id": "218",
                    "name": "Donner",
                    "Small": "6.90",
                    "Medium": "8.50",
                    "Large": "12.90"
                
                },

            ]
        },
        {
            "category": "Combination",
            "data": [
                {
                    "id": "219",
                    "name": "Korma Chasi",
                    "Small": "",
                    "Medium": "9.00",
                    "Large": ""

                },
                {
                    "id": "219",
                    "name": "Tikka Masala",
                    "Small": "",
                    "Medium": "9.00",
                    "Large": ""

                },
                {
                    "id": "220",
                    "name": "Vegetarian",
                    "Small": "7.50",
                    "Medium": "8.90",
                    "Large": "13.50"

                },
                {
                    "id": "221",
                    "name": "Chicken & Pineapple",
                    "Small": "7.50",
                    "Medium": "8.90",
                    "Large": "13.50"

                },
                {
                    "id": "222",
                    "name": "Ham & Pineapple",
                    "Small": "7.50",
                    "Medium": "8.90",
                    "Large": "13.50"

                },
                {
                    "id": "223",
                    "name": "Four Seasons",
                    "Small": "7.50",
                    "Medium": "8.90",
                    "Large": "13.50"

                },
                {
                    "id": "224",
                    "name": "Meat Feast",
                    "Small": "7.50",
                    "Medium": "8.90",
                    "Large": "13.50"

                },
                {
                    "id": "225",
                    "name": "SeaFood",
                    "Small": "7.50",
                    "Medium": "8.90",
                    "Large": "13.50"

                },
                {
                    "id": "226",
                    "name": "Volcano",
                    "Small": "7.50",
                    "Medium": "8.90",
                    "Large": "13.50"

                },
                {
                    "id": "227",
                    "name": "Istanbulian",
                    "Small": "7.50",
                    "Medium": "8.90",
                    "Large": "13.50"

                },
                {
                    "id": "228",
                    "name": "Chillies Special",
                    "Small": "7.50",
                    "Medium": "8.90",
                    "Large": "13.50"

                }
            ]
        }
    ]
}