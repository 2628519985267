import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  import Home from '../components/HomeScreen/Home'
  import Login from '../components/Login/Login'
  import Ordering from '../components/Ordering/Ordering'

  const Routing = ()=>{
      return (

        <Router>
        <div>
         
          <Switch>
          <Route  exact path="/" component={Home}/>
          <Route  exact path="/Login" component={Login}/>
          <Route exact path="/order"  component={Ordering} />
          <Route component={NotFound} />
          
          </Switch>
        </div>
      </Router>

      )
  }

  const NotFound = ()=>{
      return (
          <div>
              welcome
          </div>
      )
  }

  export default Routing;