import {Fragment, useState} from 'react'
import React from 'react'

const Home = ()=>{
    const [showModal, setShowModal] = React.useState(false);
    const [showDelivery, setShowdelivery] = React.useState(false);
    const [showCollection, setShowcollection] = React.useState(false);
    const [collector , setCollector] = useState({
      name: '', 
      tel: ''
    })

    const TakeawayHandler = e =>{
      e.preventDefault();
      const obj = {
        orderType : 'Takeaway'
      }
      window.location.href = "/order";
    }

    const collectionHandler = e=>{
      e.preventDefault();
      var obj = {
        orderType : 'collection',
        personName : collector.name,
        personTel : collector.tel
      }

      console.log(obj);
      window.location.href = "/order";

    }

    return (
        <Fragment>

    {showCollection ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div  className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-2xl font-semibold">
                    Collection Details 
                  </h3>
                  <button
                    className="p-1 ml-auto bg-yello-900 border-0 text-red  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowcollection(false)}
                  >
                    <span className=" text-red h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <form onSubmit={collectionHandler}>
                <div className="relative p-6 flex-auto">                
                <div className="container mx-auto px-4 sm:px-8">
                 <div className="py-8">
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">               
                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                <input onChange={(e)=>{setCollector({name: e.target.value, tel: collector.tel})}} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder=" Person name" required/>     
                </div>
               
                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                <input onChange={(e)=>{setCollector({tel: e.target.value, name: collector.name})}} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Phone Number" required/>
                </div>                  
                  </div>
                </div>
              </div>

                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                 <button
                    className="bg-pink-900 text-white active:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Procceed order
                  </button>
                </div>
                </form> 
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}



      {showDelivery ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div  className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-2xl font-semibold">
                    Delivery
                  </h3>
                  <button
                    className="p-1 ml-auto bg-yello-900 border-0 text-red  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowdelivery(false)}
                  >
                    <span className=" text-red h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <form onSubmit={collectionHandler}>
                <div className="relative p-6 flex-auto">                
                <div className="container mx-auto px-4 sm:px-8">
                 <div className="py-8">
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">               
                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                <input onChange={(e)=>{setCollector({name: e.target.value, tel: collector.tel})}} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder=" Person name" required/>     
                </div>
               
                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                <input onChange={(e)=>{setCollector({tel: e.target.value, name: collector.name})}} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Phone Number" required/>
                </div>                  
                  </div>
                </div>
              </div>

                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                 <button
                    className="bg-pink-900 text-white active:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Procceed order
                  </button>
                </div>
                </form> 
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}



        {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div  className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-2xl font-semibold">
                    Today's Orders
                  </h3>
                  <button
                    className="p-1 ml-auto bg-yello-900 border-0 text-red  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className=" text-red h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
         <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Home
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Res.
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Res.
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Away
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-2/5">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 w-10 h-10 hidden sm:table-cell">
                          <img className="w-full h-full rounded-full" src="https://images.unsplash.com/photo-1601046668428-94ea13437736?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2167&q=80" alt="" />
                        </div>
                        <div className="ml-3">
                          <p className="text-gray-900 whitespace-no-wrap">
                            Team 1
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap text-center">0</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap text-center">
                        3
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-2/5">
                      <div className="flex items-center float-right">
                        <div className="mr-3">
                          <p className="text-gray-900 whitespace-no-wrap text-right">
                            Team 2
                          </p>
                        </div>
                        <div className="flex-shrink-0 w-10 h-10 hidden sm:table-cell">
                          <img className="w-full h-full rounded-full" src="https://images.unsplash.com/photo-1601046668428-94ea13437736?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2167&q=80" alt="" />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-2/5">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 w-10 h-10 hidden sm:table-cell">
                          <img className="w-full h-full rounded-full" src="https://images.unsplash.com/photo-1601046668428-94ea13437736?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2167&q=80" alt="" />
                        </div>
                        <div className="ml-3">
                          <p className="text-gray-900 whitespace-no-wrap">
                            Team 3
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap text-center">0</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap text-center">
                        3
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-2/5">
                      <div className="flex items-center float-right">
                        <div className="mr-3">
                          <p className="text-gray-900 whitespace-no-wrap text-right">
                            Team 4
                          </p>
                        </div>
                        <div className="flex-shrink-0 w-10 h-10 hidden sm:table-cell">
                          <img className="w-full h-full rounded-full" src="https://images.unsplash.com/photo-1601046668428-94ea13437736?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2167&q=80" alt="" />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                 <button
                    className="bg-gray-500 text-white active:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

<div className="content">
        <div className="flex items-center justify-between w-full my-4 pl-4 sm:pr-4 ">
          <div className="mr-6">
            <h2 className="text-3xl md:text-4xl font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate"></h2>
            <div className="font-base tracking-tight text-gray-600">
            
                
            <button className="bg-red-600 font-semibold text-white p-2 w-32 rounded-full hover:bg-red-700 focus:outline-none focus:ring shadow-lg hover:shadow-none transition-all duration-300 m-2"   onClick={() => setShowModal(true)}>
                Orders
            </button>
            </div>
          </div>
          <div className="flex items-center ">
            <a href="/login" className="bg-gray-900 px-5 py-2 text-sm shadow-sm font-semibold tracking-wider text-white rounded-full hover:bg-gray-800">Admin Account</a>
          </div>
        </div>
        <div class="container" style={{width: '70%', margin: 'auto'}}>
        <div className="grid mt-8  gap-8 grid-cols-1 md:grid-cols-3 xl:grid-cols-3">
        
          <div className="flex flex-col">
            <div className="bg-yellow-400 shadow-md  rounded-3xl p-4">
              <div className="flex-none lg:flex">
                <div className=" h-full w-full lg:h-55 lg:w-27 p-10  lg:mb-0 mb-3">
                  <img src="/images/takeaway.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                  <button onClick={TakeawayHandler} className="mb-2 w-full  md:mb-0 bg-gray-900 px-5 py-2 shadow-sm tracking-wider text-white rounded-full hover:bg-gray-800" type="button" aria-label="like">Take Away</button>
                </div>
              </div>
            </div>            
          </div>
          <div className="flex flex-col">
            <div className="bg-pink-500 shadow-md  rounded-3xl p-4">
              <div className="flex-none lg:flex">
                <div className=" h-full w-full lg:h-55 lg:w-22  p-10  lg:mb-0 mb-3">
                  <img  src="/images/pickup.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl" />
                  <br/>
                  <button className="mb-2 w-full md:mb-0 bg-gray-900 px-5 py-2 shadow-sm tracking-wider text-white rounded-full hover:bg-gray-800" onClick={()=>{setShowcollection(true)}} type="button" aria-label="like">Collection</button>
                </div>
              </div>
            </div>
            </div>
            <div className="flex flex-col">
            <div className="bg-green-500 shadow-md  rounded-3xl p-4">
              <div className="flex-none lg:flex">
                <div className=" h-full w-full lg:h-55 lg:w-22  p-10  lg:mb-0 mb-3">
                  <img src="/images/delivery.png" alt="Just a flower" className=" w-full  object-scale-down lg:object-contain  lg:h-48 rounded-2xl"/>
                  <br/>
                  <button onClick={()=>{setShowdelivery(true)}} className="mb-2 md:mb-0 w-full bg-gray-900 px-5 py-2 shadow-sm tracking-wider text-white rounded-full hover:bg-gray-800" type="button" aria-label="like">Delivery</button>
                </div>
              </div>
            </div>
            </div>

          </div>
        </div>
      </div>

        </Fragment>

    )
}

export default Home;